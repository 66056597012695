<template>
  <div>
    <b-alert class="alert alert-elevate" show variant="light">
      <div class="alert-icon">
        <i class="flaticon-warning kt-font-brand"></i>
      </div>
      <div class="alert-text">
        <b>Calendars</b> The <code>v-calendar</code> component is used to
        display information in a daily, weekly, or monthly view. The daily view
        has slots for all day or timed elements, and the weekly and monthly view
        has a slot for each day. Optionally you can pass in an array of events
        and they will be rendered over the appropriate days and times.
        <a
          class="kt-link kt-link--brand kt-font-bold"
          href="https://vuetifyjs.com/en/components/calendars"
          target="_blank"
        >
          See documentation.
        </a>
      </div>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Weekly'">
          <template v-slot:preview>
            <p>
              This is an example of an event calendar with all-day and timed
              events with a type of <code>week</code>.
            </p>
            <v-row>
              <v-col>
                <v-sheet height="400">
                  <v-calendar
                    ref="calendar"
                    :events="code1.events"
                    :now="code1.today"
                    :value="code1.today"
                    color="primary"
                    type="week"
                  ></v-calendar>
                </v-sheet>
              </v-col>
            </v-row>
          </template>
          <template v-slot:html>
            {{ code1.html }}
          </template>
          <template v-slot:js>
            {{ code1.js }}
          </template>
        </KTCodePreview>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Daily'">
          <template v-slot:preview>
            <p>
              This is an example of calendar with content in each interval slot
              and a type of <code>day</code>.
            </p>
            <v-row>
              <v-col>
                <v-sheet height="400">
                  <v-calendar color="primary" type="day">
                    <template v-slot:day-header="{ present }">
                      <template v-if="present" class="text-center">
                        Today
                      </template>
                    </template>

                    <template v-slot:interval="{ hour }">
                      <div class="text-center">{{ hour }} o'clock</div>
                    </template>
                  </v-calendar>
                </v-sheet>
              </v-col>
            </v-row>
          </template>
          <template v-slot:html>
            {{ code2.html }}
          </template>
        </KTCodePreview>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Slots'">
          <template v-slot:preview>
            <p>
              Slots allow you to define the content for each day, time interval
              for the daily views, and various labels.
            </p>
            <v-row>
              <v-col>
                <v-sheet height="500">
                  <v-calendar
                    :now="code3.today"
                    :value="code3.today"
                    color="primary"
                  >
                    <template v-slot:day="{ present, past, date }">
                      <v-row class="fill-height">
                        <template v-if="past && code3.tracked[date]">
                          <v-sheet
                            v-for="(percent, i) in code3.tracked[date]"
                            :key="i"
                            :color="code3.colors[i]"
                            :title="code3.category[i]"
                            :width="`${percent}%`"
                            height="100%"
                            tile
                          ></v-sheet>
                        </template>
                      </v-row>
                    </template>
                  </v-calendar>
                </v-sheet>
              </v-col>
            </v-row>
          </template>
          <template v-slot:html>
            {{ code3.html }}
          </template>
          <template v-slot:js>
            {{ code3.js }}
          </template>
        </KTCodePreview>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Events'">
          <template v-slot:preview>
            <p>
              This is an example of a planner with additional event handlers and
              external components controlling the display of the calendar.
            </p>
            <v-row class="fill-height">
              <v-col>
                <v-sheet height="64">
                  <v-toolbar color="white" flat>
                    <v-btn
                      class="mr-4"
                      color="grey darken-2"
                      outlined
                      @click="setToday"
                    >
                      Today
                    </v-btn>
                    <v-btn color="grey darken-2" fab small text @click="prev">
                      <v-icon small>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn color="grey darken-2" fab small text @click="next">
                      <v-icon small>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ title }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu bottom right>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="grey darken-2" outlined>
                          <span>{{ typeToLabel[type] }}</span>
                          <v-icon right>mdi-menu-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="type = 'day'">
                          <v-list-item-title>Day</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'week'">
                          <v-list-item-title>Week</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'month'">
                          <v-list-item-title>Month</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = '4day'">
                          <v-list-item-title>4 days</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-toolbar>
                </v-sheet>
                <v-sheet height="600">
                  <v-calendar
                    ref="calendar2"
                    v-model="focus"
                    :event-color="getEventColor"
                    :events="events"
                    :now="today"
                    :type="type"
                    color="primary"
                    @change="updateRange"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                  ></v-calendar>
                  <v-menu
                    v-model="selectedOpen"
                    :activator="selectedElement"
                    :close-on-content-click="false"
                    offset-x
                  >
                    <v-card color="grey lighten-4" flat min-width="350px">
                      <v-toolbar :color="selectedEvent.color" dark>
                        <v-btn icon>
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-toolbar-title
                          v-html="selectedEvent.name"
                        ></v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon>
                          <v-icon>mdi-heart</v-icon>
                        </v-btn>
                        <v-btn icon>
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-card-text>
                        <span v-html="selectedEvent.details"></span>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          color="secondary"
                          text
                          @click="selectedOpen = false"
                        >
                          Cancel
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-sheet>
              </v-col>
            </v-row>
          </template>
          <template v-slot:html>
            {{ code4.html }}
          </template>
          <template v-slot:js>
            {{ code4.js }}
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/views/partials/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

/**
 * Possible issue with Calendar
 * https://github.com/vuetifyjs/vuetify/issues/9999
 */

export default {
  data() {
    return {
      code1: {
        html: `<v-row>
  <v-col>
    <v-sheet height="400">
      <v-calendar
        ref="calendar"
        :now="today"
        :value="today"
        :events="events"
        color="primary"
        type="week"
      ></v-calendar>
    </v-sheet>
  </v-col>
</v-row>`,
        js: ` export default {
    data: () => ({
      today: '2020-01-08',
      events: [
        {
          name: 'Weekly Meeting',
          start: '2020-01-07 09:00',
          end: '2020-01-07 10:00',
        },
        {
          name: 'Thomas\\' Birthday',
          start: '2020-01-10',
        },
        {
          name: 'Mash Potatoes',
          start: '2020-01-09 12:30',
          end: '2020-01-09 15:30',
        },
      ],
    }),
    mounted () {
      this.$refs.calendar.scrollToTime('08:00')
    },
  }`,
        today: "2020-01-08",
        events: [
          {
            name: "Weekly Meeting",
            start: "2020-01-07 09:00",
            end: "2020-01-07 10:00",
          },
          {
            name: "Thomas' Birthday",
            start: "2020-01-10",
          },
          {
            name: "Mash Potatoes",
            start: "2020-01-09 12:30",
            end: "2020-01-09 15:30",
          },
        ],
      },

      code2: {
        html: ` <v-row>
  <v-col>
    <v-sheet height="400">
      <v-calendar
        color="primary"
        type="day"
      >
        <template v-slot:day-header="{ present }">
          <template
            v-if="present"
            class="text-center"
          >
            Today
          </template>
        </template>

        <template v-slot:interval="{ hour }">
          <div
            class="text-center"
          >
            {{ hour }} o'clock
          </div>
        </template>
      </v-calendar>
    </v-sheet>
  </v-col>
</v-row>`,
      },

      code3: {
        html: `<v-row>
  <v-col>
    <v-sheet height="500">
      <v-calendar
        :now="today"
        :value="today"
        color="primary"
      >
        <template v-slot:day="{ present, past, date }">
          <v-row
            class="fill-height"
          >
            <template v-if="past && tracked[date]">
              <v-sheet
                v-for="(percent, i) in tracked[date]"
                :key="i"
                :title="category[i]"
                :color="colors[i]"
                :width="\`\${percent}%\`"
                height="100%"
                tile
              ></v-sheet>
            </template>
          </v-row>
        </template>
      </v-calendar>
    </v-sheet>
  </v-col>
</v-row>`,
        js: ` export default {
  data: () => ({
    today: '2019-01-10',
    tracked: {
      '2019-01-09': [23, 45, 10],
      '2019-01-08': [10],
      '2019-01-07': [0, 78, 5],
      '2019-01-06': [0, 0, 50],
      '2019-01-05': [0, 10, 23],
      '2019-01-04': [2, 90],
      '2019-01-03': [10, 32],
      '2019-01-02': [80, 10, 10],
      '2019-01-01': [20, 25, 10],
    },
    colors: ['#1867c0', '#fb8c00', '#000000'],
    category: ['Development', 'Meetings', 'Slacking'],
  }),
}`,
        today: "2019-01-10",
        tracked: {
          "2019-01-09": [23, 45, 10],
          "2019-01-08": [10],
          "2019-01-07": [0, 78, 5],
          "2019-01-06": [0, 0, 50],
          "2019-01-05": [0, 10, 23],
          "2019-01-04": [2, 90],
          "2019-01-03": [10, 32],
          "2019-01-02": [80, 10, 10],
          "2019-01-01": [20, 25, 10],
        },
        colors: ["#1867c0", "#fb8c00", "#000000"],
        category: ["Development", "Meetings", "Slacking"],
      },

      code4: {
        html: ` <v-row class="fill-height">
  <v-col>
    <v-sheet height="64">
      <v-toolbar flat color="white">
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
          Today
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small>mdi-chevron-right</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on }">
            <v-btn
              outlined
              color="grey darken-2"
              v-on="on"
            >
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Week</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = '4day'">
              <v-list-item-title>4 days</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="focus"
        color="primary"
        :events="events"
        :event-color="getEventColor"
        :now="today"
        :type="type"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="updateRange"
      ></v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card
          color="grey lighten-4"
          min-width="350px"
          flat
        >
          <v-toolbar
            :color="selectedEvent.color"
            dark
          >
            <v-btn icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>mdi-heart</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <span v-html="selectedEvent.details"></span>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="secondary"
              @click="selectedOpen = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-sheet>
  </v-col>
</v-row>`,
        js: ` export default {
  data: () => ({
    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days',
    },
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
  }),
  computed: {
    title () {
      const { start, end } = this
      if (!start || !end) {
        return ''
      }

      const startMonth = this.monthFormatter(start)
      const endMonth = this.monthFormatter(end)
      const suffixMonth = startMonth === endMonth ? '' : endMonth

      const startYear = start.year
      const endYear = end.year
      const suffixYear = startYear === endYear ? '' : endYear

      const startDay = start.day + this.nth(start.day)
      const endDay = end.day + this.nth(end.day)

      switch (this.type) {
        case 'month':
          return \`\${startMonth} \${startYear}\`
        case 'week':
        case '4day':
          return \`\${startMonth} \${startDay} \${startYear} - \${suffixMonth} \${endDay} \${suffixYear}\`
        case 'day':
          return \`\${startMonth} \${startDay} \${startYear}\`
      }
      return ''
    },
    monthFormatter () {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC', month: 'long',
      })
    },
  },
  mounted () {
    this.$refs.calendar.checkChange()
  },
  methods: {
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = this.today
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => this.selectedOpen = true, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange ({ start, end }) {
      const events = []

      const min = new Date(\`\${start.date}T00:00:00\`)
      const max = new Date(\`\${end.date}T23:59:59\`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0
        const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        const second = new Date(first.getTime() + secondTimestamp)

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: this.formatDate(first, !allDay),
          end: this.formatDate(second, !allDay),
          color: this.colors[this.rnd(0, this.colors.length - 1)],
        })
      }

      this.start = start
      this.end = end
      this.events = events
    },
    nth (d) {
      return d > 3 && d < 21
        ? 'th'
        : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    formatDate (a, withTime) {
      return withTime
        ? \`\${a.getFullYear()}-\${a.getMonth() + 1}-\${a.getDate()} \${a.getHours()}:\${a.getMinutes()}\`
        : \`\${a.getFullYear()}-\${a.getMonth() + 1}-\${a.getDate()}\`
    },
  },
}`,
      },

      today: "2020-01-08",
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      start: null,
      end: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Calendars" },
    ]);

    this.$refs.calendar.scrollToTime("08:00");

    this.$refs.calendar2.checkChange();
  },

  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: this.formatDate(first, !allDay),
          end: this.formatDate(second, !allDay),
          color: this.colors[this.rnd(0, this.colors.length - 1)],
        });
      }

      this.start = start;
      this.end = end;
      this.events = events;
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${
            a.getMonth() + 1
          }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
  },
};
</script>
